import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';

import { AuthLayoutService } from '@state/auth-layout';
import { environment } from '@env/environment';
import { FlowHttpErrorResponse } from '@app/interceptor/base.interceptor';
import { FLOWINC_DOT_COM } from '@app/global/global';
import { getPathInfo, getQueryParamsObject } from '@helpers';
import { LaunchDarklyRepository } from '@state/launch-darkly';
import { MembershipsService } from '@state/memberships';
import { TargetResourceService } from '@state/target-resource';
import { UserService } from '@state/user';

import { SessionState, SessionStore } from './session.store';

export interface Credentials {
  email: string;
  password: string;
}

export interface LoginData {
  auth_token: string;
  enable_mfa: boolean;
  errorResponse: HttpErrorResponse;
  phone_last_two?: string;
}

export interface LoginOptions {
  errorCallback?: (error: HttpErrorResponse) => void;
  redirectUrl?: string;
  skipForgotPasswordRedirect?: boolean;
}

export interface CreateByTokenResponse {
  auth_token?: string;
  error?: boolean;
  message?: string;
}

@Injectable({ providedIn: 'root' })
// TODO: It's likely most of these attributes should be on the store and not the service.
// We should revisit and refactor
export class SessionService {
  credentials: Credentials = { email: '', password: '' };
  domain = window.location.hostname.replace(/^www\./, '');
  otpToken: string;
  phoneLastTwo: string;

  constructor(
    private authLayoutService: AuthLayoutService,
    private cookieService: CookieService,
    private http: HttpClient,
    private launchDarklyRepository: LaunchDarklyRepository,
    private membershipsService: MembershipsService,
    private router: Router,
    private sessionStore: SessionStore,
    private targetResourceService: TargetResourceService,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {}

  get routePrefix(): string {
    let membership_entity_id = this.membershipsService.entityIdInRoute;
    let provider_entity_id = this.membershipsService.providerEntityIdInRoute;

    if (provider_entity_id) return `/p/${provider_entity_id}/e/${membership_entity_id}`;
    if (membership_entity_id) return `/e/${membership_entity_id}`;
    return '';
  }

  clearCredentials(): void {
    this.credentials = { email: '', password: '' };
    this.sessionStore.update({ mfa_token_view: false });
    delete this.otpToken;
    delete this.phoneLastTwo;
  }

  testEmail(value: string): boolean {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(value.toLowerCase());
  }

  resendOtp$(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/resend_otp_token`, this.credentials);
  }

  reset_password$(email: string, quiet?: boolean, org_slug?: string): Observable<any> {
    let otp_token = this.otpToken;
    return this.http.post(environment.apiUrl + '/request_password_reset', { email, org_slug, otp_token, quiet });
  }

  signup_by_token$(credentials: {
    password: string;
    reset_password_token: string;
  }): Observable<CreateByTokenResponse> {
    return this.http
      .post<CreateByTokenResponse>(environment.apiUrl + '/user/create_by_token', credentials)
      .pipe(map((data) => ({ auth_token: data?.auth_token, message: data?.message })));
  }

  peek$(token: string): Observable<Credentials> {
    return this.http
      .get<Credentials>(environment.apiUrl + '/user/peek/' + token)
      .pipe(map((data: Credentials) => ({ email: data?.email, password: data?.password })));
  }

  handleRedirect(redirectUrl?: string): void {
    if (!redirectUrl) {
      this.targetResourceService.getTargetResource$().subscribe({
        next: (targetResourceResponse) =>
          this.targetResourceService.navigateBasedOnResponse(targetResourceResponse)
      });
    } else {
      let { browser, isCurrentHost, url } = getPathInfo(redirectUrl);

      if (isCurrentHost) {
        let queryParams = {};
        if (url.search) queryParams = getQueryParamsObject(url.search);
        void this.router.navigate([url.pathname], { queryParams });
      } else {
        browser.location = url;
      }
    }
  }

  logout(options: { message?: string } = {}): void {
    this.cookieService.deleteAll('/', this.domain);
    this.userService.unsetUser();

    if (options.message) this.authLayoutService.setMessage(options.message);

    if (environment.runtimeEnv === 'production') {
      window.location.href = FLOWINC_DOT_COM;
    } else {
      void this.router.navigate(['/']);
    }

    resetStores();
  }

  setSession(token: string): void {
    // Store token in cookies. Cookie will be picked up by new apps
    // Set cookie for all domains that are in the environment.
    if (!token) return;

    this.cookieService.set('token', token, {
      domain: this.domain,
      expires: 14,
      path: '/',
      sameSite: 'Strict',
      secure: false
    });
  }

  startNewUserSession(options: { authToken: string; redirectUrl: string }): void {
    this.launchDarklyRepository.setReady(false);
    this.setSession(options.authToken);
    this.sessionStore.update({ loginSuccess: true });
    this.clearCredentials();
    this.userService.setUserFromAuth();
    this.handleRedirect(options.redirectUrl);
  }

  submitLogin$({
    errorCallback,
    redirectUrl,
    skipForgotPasswordRedirect
  }: LoginOptions): Observable<LoginData> {
    this.sessionStore.update({
      loginFormError: undefined,
      loginFormSubmitted: true,
      redirectUrl
    });

    return this.http.post<LoginData>(environment.apiUrl + '/login', this.credentials).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        let accountLocked = errorResponse.status === HttpStatusCode.Forbidden;
        this.sessionStore.update((session: SessionState) => ({
          ...session,
          accountLocked,
          loginFormError: errorResponse.error.message,
          loginFormSubmitted: false
        }));

        if (errorCallback) errorCallback(errorResponse);
        return of({ errorResponse });
      }),
      tap({
        next: ({ errorResponse, auth_token: authToken, phone_last_two: phoneLastTwo }: LoginData) => {
          if (errorResponse) return;
          if (skipForgotPasswordRedirect) this.textMessageSentSuccess();

          if (authToken) {
            this.startNewUserSession({ authToken, redirectUrl });
          } else {
            this.sessionStore.update({ loginFormSubmitted: false, mfa_token_view: true });
            this.phoneLastTwo = phoneLastTwo;
          }
        }
      })
    );
  }

  submitLoginOtp(options: {
    errorCallback?: (error: string | HttpErrorResponse) => void;
    redirectUrl?: string;
  }): void {
    let { errorCallback, redirectUrl } = options;
    this.sessionStore.update({
      loginFormError: undefined,
      loginFormSubmitted: true,
      redirectUrl
    });

    this.verifyOtp$().subscribe({
      error: (errorResponse: FlowHttpErrorResponse) => {
        let loginFormAttempts;

        this.sessionStore.update((session) => {
          loginFormAttempts = session.loginFormAttempts + 1;
          return {
            ...session,
            loginFormAttempts,
            loginFormError: errorResponse.error.message,
            loginFormSubmitted: false
          };
        });

        if (errorCallback) errorCallback(errorResponse);
      },
      next: (data: any) => {
        // TODO: document the shape of this for an interface
        let { auth_token: authToken } = data;
        this.startNewUserSession({ authToken, redirectUrl });
      }
    });
  }

  reset(email?: string): void {
    this.credentials = {
      email: email || '',
      password: ''
    };

    this.sessionStore.update({
      hasResetPassword: false,
      loginFormAttempts: 0,
      loginFormError: undefined,
      loginFormSubmitted: false,
      loginSuccess: false
    });
  }

  submitResetPassword(message: boolean, org_slug?: string): void {
    this.sessionStore.update({
      loginFormError: undefined,
      loginFormSubmitted: true
    });

    this.reset_password$(this.credentials.email, null, org_slug).subscribe({
      error: (error) => {
        this.sessionStore.update({
          loginFormError: error.message,
          loginFormSubmitted: false
        });
      },
      next: (data) => {
        if (data && data['enable_mfa']) {
          this.sessionStore.update({ loginFormSubmitted: false, requiresTokenVerification: true });
          this.phoneLastTwo = data['phone_last_two'];
        } else {
          this.sessionStore.update({
            hasResetPassword: true,
            loginFormSubmitted: false,
            requiresTokenVerification: false
          });
        }

        if (message) {
          this.textMessageSentSuccess();
        }
      }
    });
  }

  textMessageSentSuccess(): void {
    this.snackBar.open('Text message sent.');
  }

  verifyOtp$(removePassword = false, enable_mfa = false): Observable<any> {
    let credentials_params;

    if (removePassword) {
      credentials_params = { email: this.credentials.email, enable_mfa };
    } else {
      credentials_params = this.credentials;
    }

    let params = Object.assign({ otp_token: this.otpToken }, credentials_params);
    return this.http.post(`${environment.apiUrl}/authorize_otp`, params);
  }
}
