export const environment = {
  apiUrl: 'https://api.platformflowinc.app',
  APP_CUE: '80462',
  docsUrl: 'https://platformflowinc.app',
  FILESTACK_BUCKET: 'flow-ke-staging',
  FILESTACK_KEY: 'Az9rTdBiSYW7iLy3V0cP7z',
  fliUrl: 'https://investor.platformflowinc.app',
  flowAirtableUrl: 'https://airtable.com/embed/shr9shEhRT4c1ZcbC',
  FROALA_KEY: 'fIE3A-9C1H2F2B5C4C3td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3E2D4F2E2C3B3C4==',
  GOOGLE_ANALYTICS_APP: 'UA-135675240-3',
  hmr: ('false' as string) === 'true',
  HOTJAR_SITE_ID: '2814669',
  JWT_WHITELIST_DOMAINS: 'platformflowinc.app,api.platformflowinc.app,flow-api-platform-1.herokuapp.com,flow-client-platform-1.pages.dev'.split(','),
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '6480d2d2f3938413be7bb828',
  PLAID_ENV: 'sandbox',
  production: ('true' as string) === 'true',
  PUSHER_CLUSTER: 'us3',
  PUSHER_KEY: '601af15959108ea3f36c',
  rollbarErrorVerbose: ('true' as string) === 'true',
  rollbarToken: '59b1bd7c607b477693fc05e1653c1354',
  runtimeEnv: 'platform-1',
  svbAirtableUrl: 'https://airtable.com/embed/shr1tGYtE6Ux6ysTn',
  webinarAirtableUrl: 'https://airtable.com/embed/shrbZ2r9aZFux5Cks?backgroundColor=orange',
  webinarSignUpAirtableUrl: 'https://airtable.com/embed/shrwVhBHFbjBpJLb0?backgroundColor=orange',
  ZENDESK_ACCOUNT_URL: ''
};
