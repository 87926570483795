import { Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '@global';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class FlowDotComRedirectGuard  {
  constructor(private router: Router) {}

  canActivate(state: RouterStateSnapshot): boolean {
    let path: string = state.url;

    if (environment.runtimeEnv !== 'production') {
      return true;
    }

    switch (path) {
      case '/terms-of-use':
        window.location.href = TERMS_OF_USE_URL;
        break;
      case '/privacy':
        window.location.href = PRIVACY_POLICY_URL;
        break;
      default:
        void this.router.navigate(['/login']);
    }

    return false;
  }
}
