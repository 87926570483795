import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationService, PublicInfo } from '@state/organization';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class PublicInfoResolver {
  constructor(private organizationService: OrganizationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PublicInfo> {
    let urlSlug: string = route.params.organization_slug;
    return this.organizationService.getAndStorePublicInfo$(urlSlug);
  }
}
