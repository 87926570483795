import { BaseModel, ID } from '@state/base';
import { FundInvestmentAccount } from '@state/fund';
import { EntityType, InvestmentAccountValues } from '@component/entity-form-modal';
import { MemberInviteParams, MemberRoleType } from '@state/memberships';

// TODO: Update interfaces in this file
// a lot of it is very off
// ticket: https://www.pivotaltracker.com/story/show/184871751

export interface EntityAddress {
  addressable_id?: ID;
  addressable_type?: string;
  city: string;
  country: string;
  county?: string;
  created_at?: string;
  full_address?: string;
  id?: ID;
  is_domestic?: boolean;
  label?: string;
  lat?: string;
  line_one: string;
  line_two?: string;
  lng?: string;
  locality?: string;
  notes?: string;
  postal: string;
  state_code_upcase?: string;
  state_code?: string;
  state_upcase?: string;
  state: string;
  street_address?: string;
  type?: string;
  updated_at?: string;
}

export interface BankAccount extends BaseModel {
  account_name?: string;
  account_number?: string;
  account_routing?: string;
  address?: EntityAddress;
  bank_address?: string;
  bank_contact_email?: string;
  bank_contact_name?: string;
  bank_contact_phone?: string;
  bank_name?: string;
  beneficiary_address?: string;
  branch?: string;
  display_name?: string;
  entity_id: ID;
  full_address?: string;
  instructions?: string;
  notes?: string;
  routing_number?: string;
  swift_code?: string;
  wire_instructions?: string;
}

export enum EntityRole {
  Fund = 'fund',
  GeneralPartner = 'general_partner',
  InvestmentProfile = 'investment_profile',
  ManagementCompany = 'management_company',
  MasterFund = 'master_fund',
  MasterSeries = 'master/series',
  Organization = 'organization',
  PortfolioCompany = 'portfolio_company',
  Team = 'team'
}

export const humanizedEntityRole = {
  fund: 'Fund',
  general_partner: 'General Partner',
  investment_profile: 'Investment Profile',
  management_company: 'Management Company',
  master_fund: 'Master Fund',
  organization: 'Organization',
  portfolio_company: 'Portfolio Company'
};

export interface DirectOrganization extends BaseModel {
  allow_data_room?: boolean;
  allow_cap_call_events?: boolean;
  entity_id: ID;
  name: string;
  saas_enabled: boolean;
  service_agreement_amended_on: string;
  service_agreement_signed_on: string;
  service_enabled: boolean;
  url_slug: string;
}

export interface EntityTeam {
  clients: EntityTeamClient[];
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  display_name: string;
  id: ID;
  member_names: string[];
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface EntityTeamClient extends BaseModel {
  active_investments: number;
  assets_under_management: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_title: string;
  display_name: string;
  funds_managed: number;
  legal_name: string;
  logo_url: string;
  primary_contacts: EntityTeamClientContact[];
  total_invested: string;
  type_humanized: string;
}

export interface EntityTeamClientContact {
  full_name: string;
  role: string;
}

export interface Entity extends BaseModel {
  account_name?: string;
  account_number?: string;
  address: EntityAddress;
  bank_account: BankAccount;
  bank_account_state?: string;
  bank_address?: string;
  bank_name?: string;
  bank_routing?: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_title: string;
  date_of_formation: string;
  direct_organization?: DirectOrganization;
  direct_saas_enabled: boolean;
  display_name: string;
  disregarded_entity_legal_name: string;
  disregarded_entity_tax_id: string;
  draft: boolean;
  entity_type_humanized: string;
  entity?: Entity;
  flow_managed: boolean;
  formation_country: string;
  formation_state: string;
  formed_on_formatted: string;
  formed_on: string;
  full_address: string;
  full_cpc?: boolean;
  investment_account?: FundInvestmentAccount;
  is_organization?: boolean;
  legal_name: string;
  logo_url: string;
  name: string;
  notes: string;
  organization_id: ID;
  parent_name: string;
  parent: Entity;
  primary_color?: string;
  provider: boolean;
  roles: EntityRole[];
  routing_number: string;
  saas_enabled: boolean;
  state_of_incorporation: string;
  stock_transfer_details: StockTransferDetails;
  subtype: string;
  tax_id_last_four: string;
  tax_id_type: string;
  tax_id: string;
  trust_subtype: string;
  trust_type: string;
  type: EntityType;
  vehicle_id?: ID;
  wire_beneficiary?: string;
  wire_instructions?: string;
  // TODO: Implement interfaces / types for these
  // https://app.asana.com/0/1151173403660257/1170885728611409/f
  // direct_organization?: null;
  // investment_account?: null;
}

export interface EntityTeamMember extends BaseModel {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  entity_members: EntityMembership[];
}

export interface EntityMember extends BaseModel {
  communication_types: EntityMemberCommunicationType[];
  email: string;
  entity_id?: string;
  entity_member_id: ID;
  entity_member_roles: EntityMemberRole[];
  first_name: string;
  invitation_state?: string;
  invitation_token?: string;
  last_name: string;
  middle_name?: string;
  name: string;
  role: MemberRoleType;
  role_humanized?: string;
  status: string;
  title?: string;
  user_id: string;
  user_verified?: boolean;
}

export interface EntityMembership {
  display_name: string;
  entity_id: ID;
  role: string;
  role_humanized: string;
  vehicle_id: ID;
}

export enum EntityMemberRoleType {
  Administrator = 'administrator',
  FundAdministrator = 'fund_administrator',
  GeneralPartner = 'general_partner',
  LegalTeam = 'legal_team',
  Limited = 'limited',
  Owner = 'owner',
  PrimaryContact = 'primary_contact',
  Viewer = 'viewer'
}

export interface EntityMemberRole {
  archived_at?: string;
  created_at: string;
  entity_member_id: ID;
  id: ID;
  role: EntityMemberRoleType;
}

export enum EntityMemberCommunicationType {
  CapitalAccountStatements = 'capital_account_statements',
  CapitalCallNotices = 'capital_call_notices',
  Distributions = 'distributions',
  GeneralCommunications = 'general_communications',
  Legal = 'legal',
  QuarterlyAndAnnualReports = 'quarterly_and_annual_reports',
  Tax = 'tax'
}

export const entityMemberCommunicationTypeLabels = {
  capital_account_statements: 'Capital Account Statements',
  capital_call_notices: 'Capital Call Notices',
  distributions: 'Distributions',
  general_communications: 'General Communications',
  legal: 'Legal',
  quarterly_and_annual_reports: 'Quarterly And Annual Reports',
  tax: 'Tax'
};

export interface StockTransferDetails {
  additional_instructions?: string;
  bank_name?: string;
  beneficiary_account_number?: string;
  beneficiary_name?: string;
  dtc_number?: string;
  intermediary_bank?: string;
  intermediary_bank_account_number?: string;
}

export const entityMemberRoleLabels = {
  administrator: 'Administrator',
  fund_administrator: 'Fund Admin',
  general_partner: 'General Partner',
  legal_team: 'Legal Team',
  limited: 'Limited',
  owner: 'Owner',
  primary_contact: 'Primary Contact',
  viewer: 'View Only'
};

export const entityMemberAccessLabel = {
  administrator: 'Administrator',
  limited: 'Limited',
  owner: 'Owner',
  viewer: 'View Only'
};

export interface PrimaryContact extends BaseModel {
  email: string;
  first_name: string;
  full_name: string;
  last_name?: string;
  middle_name?: string;
  name: string;
  verified?: boolean;
}

export type IDLike = ID | string;

export interface AddressParams {
  city?: string;
  country?: string;
  is_domestic?: boolean;
  label?: string;
  line_one?: string;
  line_two?: string;
  notes?: string;
  postal?: string;
  state?: string;
  state_code?: string;
}

export interface EntityParams {
  // address can't be empty via entity-form-modal.component.ts
  address: AddressParams;
  bank_account?: {
    account_name?: string;
    account_number?: string;
    address?: AddressParams;
    address_params?: AddressParams; // Investigate if this is deprecated for address
  };
  contact_email?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_title?: string;
  display_name?: string;
  formation_country?: string;
  formation_state?: string;
  formed_on?: string;
  // investment_account can't be empty via entity-form-modal.component.ts
  investment_account: InvestmentAccountValues;
  legal_name?: string;
  logo_url?: string;
  notes?: string;
  parent_entity_id?: IDLike;
  primary_color?: string;
  provider?: boolean;
  entity_roles?: string[];
  subtype?: string;
  tax_id?: string;
  tax_id_type?: string;
  trust_subtype?: string;
  trust_type?: string;
  type?: string;
}

export interface HierarchyPayload {
  children: Entity[];
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  entity: Entity;
  id: ID;
  parent?: Entity;
  parent_name: string;
  siblings: Entity[];
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface AddMemberOptions {
  contact_id?: ID;
  entityId: ID;
  member: MemberInviteParams;
  message?: any;
  organization_id?: ID;
  providerId?: ID;
  send_email?: boolean;
  subject?: any;
  vehicle_id?: ID;
}

export interface GetEntityMembersParams {
  entity_id: ID;
  users?: boolean;
  include_invisible?: boolean;
  contact_id?: ID;
  vehicle_id?: ID;
}

export interface UpdateEntityMemberParams {
  contact_id?: ID;
  entity_id: ID;
  entity_member_roles?: EntityMemberRoleType[];
  member_id: ID;
  member: MemberInviteParams;
  vehicle_id?: ID;
}

export interface RemindMemberParams {
  contact_id?: ID;
  entity_id: ID;
  member_id: ID;
  provider_id?: ID;
  vehicle_id?: ID;
}

export interface RemoveEntityMemberParams {
  entity_id: ID;
  member_id: ID;
  provider_id?: ID;
  contact_id?: ID;
  vehicle_id?: ID;
}

export interface EntityIds {
  entity_id: ID;
  entity_member_id: ID;
  investment_id?: ID;
}
