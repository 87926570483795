import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

import { EntityTeamMember } from '@state/entities';

import { PublicInfo, PortfolioReport } from './organization.model';

interface OrganizationProps {
  publicInfo?: PublicInfo;
  portfolioReport?: PortfolioReport;
  users?: EntityTeamMember[];
}

@Injectable({ providedIn: 'root' })
export class OrganizationRepository {
  private _store$ = createStore(
    { name: 'organization' },
    withProps<OrganizationProps>({})
  );

  publicInfo$ = this._store$.pipe(select((state) => state.publicInfo));
  portfolioReport$ = this._store$.pipe(select((state) => state.portfolioReport));
  users$ = this._store$.pipe(select((state) => state.users));

  constructor() {}

  getColor(): string {
    let publicInfo = this._store$.getValue().publicInfo;
    return publicInfo?.primary_color;
  }

  getPortfolioReport(): PortfolioReport {
    return this._store$.getValue().portfolioReport;
  }

  reset(): void {
    this._store$.reset();
  }

  setPortfolioReport(portfolioReport: PortfolioReport): void {
    this._store$.update(setProp('portfolioReport', portfolioReport));
  }

  setPublicInfo(publicInfo: PublicInfo): void {
    this._store$.update(setProp('publicInfo', publicInfo));
  }

  setUsers(users: EntityTeamMember[]): void {
    this._store$.update(setProp('users', users));
  }
}
